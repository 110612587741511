<template>
  <div class="record_cancel">
    <transition name="fade">
      <load v-if="isLoading"></load>
    </transition>
    <div class="head">
      <top title="直付列表"></top>
    </div>
    <div class="haders">
      <div class="day">
        <p @click="choose">{{ timer }}<i class="iconfont icon-xiangxia" size="14"></i></p>
        <van-popup v-model="show" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="year-month"
            title="时间"
            :min-date="minDate"
            confirm-button-text="完成"
            :max-date="maxDate"
            :formatter="formatter"
            @confirm="success"
            @cancel="show = false"
          />
        </van-popup>
      </div>
      <p style="flex-shrink:0">
        直付总额<span>￥{{ total }}</span>
      </p>
    </div>
    <div class="contain" ref="box">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell
          v-for="item in forestlist"
          :key="item.id"
          @click="detail(item.orderNo,item.status)"
        >
          <div class="list">
            <div class="pic">
              <img :src="item.coverImg" alt="" />
            </div>
            <div class="time">
              <p class="name">
                <span>{{ item.pharmacyName }}({{item.pharmacyCode}})</span
                ><span class="price" :style="[{color:(item.status==1?' #518E37':'red')}]">{{item.status==1?'-':'+'}}￥{{item.writtenOffAmount }}</span
                >
              </p>
              <p class="data">{{ item.createTime }}</p>
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
import storage from "../../../src/utils/storeage";
import load from "../../components/loading.vue";
import {totalWriteOff,recordList } from '@/api/_api/cancel'
export default {
  components: {  load },
  data() {
    return {
      arr: [],
      total: 0,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      show: false,
      timer: '',
      loading: true,
      finished: true,
      page: 1,
      forestlist: [ ] ,
      length: 0,
      shopname: "",
      year: 0,
      month: 0,
      scroll: 0,
      isLoading: false,
      cardnum: sessionStorage.getItem("cardNO")
    };
  },
  methods: {
    //触底加载
    async onLoad() {
      // let res;
      // if (this.timer == "全部") {
      //   res = await detaillist(this.$cookies.get("uid"), this.page, 30, this.cardnum);
      // } else {
      //   res = await detaillist(
      //     this.$cookies.get("uid"),
      //     this.page,
      //     10,
      //     this.cardnum,
      //     this.month,
      //     this.year
      //   );
      // }
      // //全部列表获取
      // this.length = res.data.data.total;
      // //遍历渲染
      // res.data.data.list.forEach((item) => {
      //   let detaillist = {
      //     id: item.id,
      //     data: item.creDtm,
      //     price: item.payAmt,
      //     orgname: item.orgName,
      //     orgid: item.orgId,
      //     remark: item.remark,
      //     orderNo: item.orderNo,
      //     creDtm: item.creDtm,
      //     storename: "",
      //     trxSeq: item.trxSeq,
      //   };
      //   //push进去
      //   this.forestlist.push(detaillist);
      //   //药房名字接口
      //   storenum(item.orgId).then((res) => {
      //     this.forestlist.forEach((item) => {
      //       item.storename = res.data.data;
      //       this.shopname = res.data.data;
      //     });
      //   });
      // });
      // // this.isLoading = false;
      // // 加载状态结束
      // this.loading = false;
      // //记住滚轮
      // this.$nextTick(() => {
      //   if (storage.getItem("scrolltop")) {
      //     this.$refs.box.scrollTop = storage.getItem("scrolltop");
      //   }
      // });
      // // 数据全部加载完成
      // if (this.forestlist.length >= this.length) {
      //   this.finished = true;
      // }
      // this.page++;
    },
    //选择时间
    choose() {
      this.show = true;
    },
    //确定时间年月
    success(value) {
      this.getList();
      this.timer = `${value.getFullYear()}年${value.getMonth()+1}月`
      this.show = false;
      // this.page = 1;
      // this.forestlist = [];
      // this.finished = false;
      // this.loading = true;
      // this.onLoad();
    },
    //弹出框
    showPopup() {
      this.show = true;
    },
    //选择时间器
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      this.timer = val;
      return val;
    },
    //跳转详情
    detail(id,status) {
      this.$router.push({
        path: "/detail",
        query: { orderNo: id,status:status},
      });
      // storage.setItem({ value: item, name: "shopdetail", expires: 86400000 });
    },
    //初始时间是当天时间
    init(){
      let date = this.currentDate;
      let month = `${date.getMonth()+1}`;
      month = month.length == 1?`0${month}`:month
      let recordTime = `${date.getFullYear()}-${month}`;
      totalWriteOff({recordTime}).then(res=>{
        if(res.code == 200){
          this.total = res.data.total
        }
      })
    },
    //列表
    getList(){
      this.init();
      let date = this.currentDate;
      let month = `${date.getMonth()+1}`;
      month = month.length == 1?`0${month}`:month
      let recordTime = `${date.getFullYear()}-${month}`;
      recordList({recordTime}).then(res=>{
        if(res.code == 200){
          this.forestlist = res.data;
          this.forestlist.forEach(item=>{
            item.coverImg?item.coverImg = JSON.parse(item.coverImg)[0].url:''
            
          })
        }
        this.finished = true;
      })
    }
  },
  mounted() {
    let date = new Date();
    let year = date.getFullYear();
    let mon = date.getMonth() + 1;
    this.timer = `${year}年${mon}月`
    // this.init();
    this.getList();
    //监听滚动滑动
    this.$refs.box.addEventListener(
      "scroll",
      () => {
        storage.setItem({
          value: this.$refs.box.scrollTop,
          name: "scrolltop",
          expires: 86400000,
        });
      },
      false
    );
  },
};
</script>

<style lang="scss" scoped>

.price{
  
  font-size: vw(36);
  font-weight: 700;
  flex-shrink: 0;
}
.record_cancel {
  width: 100%;
  position: relative;
  overflow: hidden;
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .head {
    width: 100%;
  }
  .haders {
    width: 95%;
    margin: vw(30) auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .day {
      width: 60%;
      p {
        font-size: vw(28);
        margin-left: vw(30);
        i {
          margin-left: vw(10);
        }
      }
    }
    p {
      font-size: vw(28);
    }
    span {
      font-weight: 700;
      margin-left:vw(12);
      font-size: vw(36);
      color: $indexcolor;
    }
  }
  .contain {
    height: vw(1100);
    z-index: 100;
    margin:0 vw(24);
    border-radius: vw(8);
    overflow-y: auto;
    .van-cell {
      display: flex;
      padding: vw(28);
    }
    .list {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      .pic {
        margin-right: vw(32);
        img {
          width: vw(80);
          height: vw(80);
        }
      }
      .time {
        width: 100%;
        .name {
          font-weight: 600;
          font-size: vw(32);
          display: flex;
          color: #333;
          align-items: center;
          justify-content: space-between;
        }
        .data {
          font-size: vw(24);
          color: #999;
          
        }
      }
    }
  }
}
</style>
