<template>
  <div class="loading">正在加载中...</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  left:40%;
  top:50%;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}
</style>
